import axios from "axios";
import type { I_Response } from "@/lib/models/composables/useAxios/interfaces";
import { getBaseUrlFunc } from "@/lib/utils/getBaseUrl";

export const axiosRequest = async (
  url: string,
  method: string,
  options: any = null
): Promise<I_Response<any, any>> => {
  let data = null;
  let error = null;

  let currentUrl = "";
  if (url[0] === "/") {
    currentUrl = url.substring(1);
  } else {
    currentUrl = `${getBaseUrlFunc()}${url}`;
  }
  try {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      method: method,
      url: currentUrl,
      data: method === "POST" ? options : null,
      params: method === "GET" ? options : null,
    });

    data = response.data;
  } catch (err) {
    error = err;
  }

  return { data, error };
};
