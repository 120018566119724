import {
  createCookie,
  getCookie,
  getRandomFileName,
} from "@/store/modules/drsPlayground/lib/utils/storage";
import store from "@/store";

export const setCookiesAndGetItems = async (data: any): Promise<void> => {
  if (data?.task_id && data?.enc_key) {
    if (!getCookie("webdrsid")) createCookie("webdrsid", data.enc_key, 10);
    createCookie("task_id_" + getRandomFileName().toString(), data.task_id, 10);
  }
  await store.dispatch("drsPlayground/A_GET_DRS_ITEMS");
};
