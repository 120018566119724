import {
  API_I_GeneratedTask,
  I_GeneratedTask,
} from "@/store/modules/drsPlayground/lib/models/interfaces";
import { T_StatusBlockType } from "@/components/ui/uiStatusBlock/lib/models/types";
import store from "@/store";

export const constructTask = (data: API_I_GeneratedTask): I_GeneratedTask => {
  let currentStatus: T_StatusBlockType | null = null;

  switch (data.task_status) {
    case "SUCCESS":
      currentStatus = "success";
      break;
    case "PENDING":
      currentStatus = "pending";
      break;
    case "FAILURE":
      currentStatus = "failure";
      break;
  }

  return {
    progressBar: data.progress_bar,
    id: data.task_id,
    name: data.task_name,
    result0: data.task_result0,
    result1: data.task_result1,
    status: currentStatus,
    successful: data.task_successful,
    time: data.task_time,
    type: data.task_type,
  };
};

export const checkNotSuccessStatus = async (): Promise<void> => {
  const tasksStore: I_GeneratedTask[] =
    store.getters["drsPlayground/getGeneratedTasks"];
  const pendingItemKeys = [];

  for (const item of tasksStore) {
    if (item.status === "pending") {
      const data = await store.dispatch(
        "drsPlayground/A_GET_DRS_ITEM_BY_ID",
        item.id
      );
      if (data.status === "pending") {
        pendingItemKeys.push(item.id);
      }
    }
  }
  if (pendingItemKeys.length) {
    setTimeout(() => {
      checkNotSuccessStatus();
    }, 3000);
  }
};
