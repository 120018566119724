import { I_GenerateDrsForm } from "@/components/templates/drsPlayground/modals/generateDrs/lib/models/interfaces";
import { I_GeneratedTask } from "@/store/modules/drsPlayground/lib/models/interfaces";
import { axiosRequest } from "@/composables/useAxios";
import {
  createCookie,
  getAllTaskCookies,
  getCookie,
  scanUserTask,
} from "@/store/modules/drsPlayground/lib/utils/storage";
import { constructTask } from "@/store/modules/drsPlayground/lib/utils/constructTask";
import { setCookiesAndGetItems } from "@/store/modules/drsPlayground/lib/utils/setCookiesAndGetItems";

export default {
  async A_GENERATE_DRS_MANUALLY(
    _context: any,
    payload: I_GenerateDrsForm
  ): Promise<string | void> {
    const webDrsIdCookie = getCookie("webdrsid");
    const sendData: any = {
      type: 1,
      drsfileinput: "RANDOMNAME",
      json_conf: {
        host_ram_averege: payload.hostRamAverege,
        host_ram_min: payload.hostRamMin,
        host_ram_max: payload.hostRamMax,
        host_cpu_min: payload.hostCpuMin,
        host_cpu_max: payload.hostCpuMax,
        vm_ram_averege: payload.vmRamAverege,
        vm_ram_min: payload.vmRamMin,
        vm_ram_max: payload.vmRamMax,
        vm_cpu_min: payload.vmCpuMin,
        vm_cpu_max: payload.vmCpuMax,
        genshare: 0,
        generate_group_random: 0,
        nhosts: 4,
        nvms: 10,
        host_cpu_averege: 64,
        vm_cpu_averege: 4,
        do_square: 3,
        lowboundneed: 1,
        balance_individual_load: 5,
        highboundneed: 1,
        super_highboundneed: 1,
        allowed_move_items: 0,
        CPUs_influence: 1,
        RAMs_influence: 1,
        add_hint: 0.5,
        do_move_limit: 3,
        do_share_max_limit: 5,
        moving_square: 0,
        allow_underload: 0,
      },
    };
    if (webDrsIdCookie) sendData.webdrsid = webDrsIdCookie;
    const { data } = await axiosRequest("tasks", "POST", sendData);

    await setCookiesAndGetItems(data);
  },
  async A_GENERATE_DRS_RANDOMLY(
    _context: any,
    payload: [number, number]
  ): Promise<string | void> {
    const webDrsIdCookie = getCookie("webdrsid");
    const sendData: any = {
      type: 2,
      drsfileinput: "RANDOMNAME",
      json_conf: {
        cpu_to_ram_balance: payload[0],
        vm_to_host_balance: payload[1],
        genshare: 4,
        generate_group_random: 0,
        nhosts: 4,
        nvms: 10,
        host_ram_averege: 64,
        host_ram_min: 32,
        host_ram_max: 312,
        host_cpu_averege: 64,
        host_cpu_min: 32,
        host_cpu_max: 312,
        vm_ram_averege: 4,
        vm_ram_min: 2,
        vm_ram_max: 12,
        vm_cpu_averege: 4,
        vm_cpu_min: 2,
        vm_cpu_max: 12,
        do_square: 3,
        lowboundneed: 1,
        balance_individual_load: 5,
        highboundneed: 1,
        super_highboundneed: 1,
        allowed_move_items: 0,
        CPUs_influence: 1,
        RAMs_influence: 1,
        add_hint: 0.5,
        do_move_limit: 3,
        do_share_max_limit: 5,
        moving_square: 0,
        allow_underload: 0,
      },
    };
    if (webDrsIdCookie) sendData.webdrsid = webDrsIdCookie;
    const { data } = await axiosRequest("tasks", "POST", sendData);

    await setCookiesAndGetItems(data);
  },
  async A_GET_DRS_ITEM_BY_ID(
    _: any,
    payload: string
  ): Promise<I_GeneratedTask> {
    const webDrsIdCookie = getCookie("webdrsid") || "";
    const sendData = {
      webdrsid: webDrsIdCookie,
    };

    const data = await axiosRequest(
      `tasks/${payload}`,
      "POST",
      JSON.stringify(sendData)
    );

    if (data?.data?.enc_key) {
      createCookie("webdrsid", data.data.enc_key, 10);
    }

    return constructTask(data.data);
  },
  async A_GET_DRS_ITEMS(context: any, payload: string[] = []): Promise<void> {
    const result: I_GeneratedTask[] = [];
    const pendingKeys: string[] = [];

    const userTaskCandidates = getAllTaskCookies();
    const currentKeys = payload.length
      ? payload
      : scanUserTask(userTaskCandidates);
    for (const key of currentKeys) {
      const data = await context.dispatch("A_GET_DRS_ITEM_BY_ID", key);
      if (data.status.toLowerCase() === "pending") {
        pendingKeys.push(key);
      }
      data && result.push(data);
    }
    context.commit("M_GET_DRS_ITEMS", result);

    if (pendingKeys.length) {
      setTimeout(() => {
        context.dispatch("A_GET_DRS_ITEMS", pendingKeys);
      }, 2000);
    }
  },
};
