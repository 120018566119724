import {
  I_DrsPlaygroundState,
  I_GeneratedTask,
} from "@/store/modules/drsPlayground/lib/models/interfaces";

export default {
  getGeneratedTasks: (state: I_DrsPlaygroundState): I_GeneratedTask[] => {
    return state.generatedTasks;
  },
};
