import {
  I_DrsPlaygroundState,
  I_GeneratedTask,
} from "@/store/modules/drsPlayground/lib/models/interfaces";

export default {
  M_GET_DRS_ITEMS(
    state: I_DrsPlaygroundState,
    payload: I_GeneratedTask[]
  ): void {
    state.generatedTasks = payload;
  },
};
